import ru from '../i18n/ru.json';
import en from '../i18n/en.json';

Array.prototype.last = function () {
  return this[this.length - 1]
}
document.addEventListener("DOMContentLoaded", (event) => {
  const data = JSON.parse(localStorage.genieLanding || '{}');
  // language settings
  const { lang } = data;

  // language init
  i18next.init({
    lng: lang || 'EN',
    debug: true,
    resources: {
      EN: {
        translation: en,
      },
      RU: {
        translation: ru,
      }
    }
  });

  // handle submit form
  let submited = false;
  document.querySelector('form')?.addEventListener('submit', (event) => {
    event.preventDefault();

    if (!submited) {
      const formData = new FormData(event.target);
      document.querySelector('form').querySelector('button').style.opacity = 0.3
      submited = true;
      fetch('/api/v1/landing/claims', {
        method: 'post',
        body: formData,
      }).then(() => {
        document.querySelector('.joinForm').style.display = 'none';
        document.querySelector('.successForm').style.display = 'flex';
      })
    }
  });

  // add gtm data
  let value;
  if (location.search) {
    data.gtm = location.search;
    localStorage.genieLanding = JSON.stringify(data);
    value = location.search;
  } else {
    value = JSON.parse(localStorage.genieLanding || '{}')?.gtm;
  }

  document.querySelector('input[type=hidden]').value = value;


  // carousel
  const carousels = [...document.querySelectorAll('.carousel')];

  carousels.map((carousel, i) => {
    setInterval(() => {
      const items = [...carousel.children];
      const last = items.last();
      last.style.marginLeft = `-${last.clientWidth}px`;
      carousel.prepend(last);
      setTimeout(() => {
        last.style.marginLeft = `-0px`;
      }, 50)
    }, 3000)
  });
});
